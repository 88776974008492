import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import logo from "../assets/annalaumi.gif"
import { BaseLayout } from "../components/base-layout"
import { Masonry } from "../components/masonry"
import { MasonryImage } from "../components/masonry-image"
import { MasonryOverlay } from "../components/masonry-overlay"
import { MobileLogo } from "../components/mobile-logo"
import { SayHi } from "../components/say-hi"
import { createProjectLink } from "../library/create-project-link"

const imageHeight = "25rem"
const Container = styled.div`
  margin: 0 300px;
  @media (max-width: 1400px) {
    margin: 0 150px;
  }
  @media (max-width: 1100px) {
    margin: 0 50px;
  }
  @media (max-width: 768px) {
    margin: unset;
    padding-bottom: 3rem;
  }
  display: flex;
  flex-direction: column;
  position: relative;
`

const LogoText = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -1rem;
`

const LogoTextRow = styled.span`
  font-family: "Source Sans Pro", sans-serif;
  color: rgb(0, 0, 176);
  text-transform: lowercase;
  font-size: 1.2rem;
  margin-left: 20%;
  line-height: 1.2;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  @media (max-width: 768px) {
    display: none;
  }
`
const MasonryVideo = styled.video`
  width: 100%;
`

const ImprintLink = styled(Link)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: rgb(0, 0, 176);
`

function isVideo(project_image) {
  return /^.*\.mp4$/.test(project_image.publicURL)
}

const ProjectImage = ({ projectImage, alt }) => {
  if (projectImage.childImageSharp) {
    return (
      <Img fluid={projectImage.childImageSharp.fluid} fadeIn={true} alt={alt} />
    )
  }

  if (isVideo(projectImage)) {
    return (
      <MasonryVideo autoPlay={true} loop={true} muted={true} playsInline={true}>
        <source src={projectImage.publicURL} type="video/mp4" />
      </MasonryVideo>
    )
  }
  return <MasonryImage src={projectImage.publicURL} alt={alt} />
}

export default ({ data }) => {
  return (
    <>
      <BaseLayout>
        <Helmet>
          <title>Anna Laumer - communication & motion design</title>
          <meta name="robots" content="index, follow" />
          <meta
            name="description"
            content="Portfolio of Würzburg & Bolzano based communication/motion/eco-social designer Anna Laumer ⚡"
          />
        </Helmet>
        <Container>
          <MobileLogo />
          <Masonry maxHeight={imageHeight}>
            <LogoContainer>
              <MasonryImage src={logo} alt={"Anna Laumer logo"} />
              <LogoText>
                <LogoTextRow>COMMUNICATION &</LogoTextRow>
                <LogoTextRow>MOTION DESIGN</LogoTextRow>
              </LogoText>
            </LogoContainer>
            {data.allStrapiProject.edges
              .map(e => e.node)
              .map(item => {
                return (
                  <>
                    <MasonryOverlay
                      name={item.name}
                      description={item.description}
                      href={createProjectLink({
                        name: item.name,
                        strapiId: item.strapiId,
                      })}
                    />
                    <ProjectImage
                      projectImage={item.project_image}
                      alt={`${item.name} - ${item.description}`}
                    />
                  </>
                )
              })}
          </Masonry>
          <SayHi />
          <ImprintLink to={"/impressum"}>Impressum</ImprintLink>
        </Container>
      </BaseLayout>
    </>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiProject(
      sort: { fields: display_order, order: ASC }
      filter: { hide: { ne: true } }
    ) {
      edges {
        node {
          id
          name
          strapiId
          description
          project_image {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
        }
      }
    }
  }
`
