function createSlug(value) {
    return value.toLowerCase()
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
        .replace(/\./g, '')
        .replace(/,/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/[^a-z0-9]/g, '')
        .replace(/\s/g, '-')
}

module.exports = {
    createProjectLink({ strapiId, name }) {
        return `/projects/${strapiId}-${createSlug(name)}`;
    }
};