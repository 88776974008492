import React from 'react';
import styled from 'styled-components';

import sayHi from '../assets/say-hi.mp4';

const Container = styled.div`
    margin-top: 8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 3rem;
`;

const Video = styled.video`
    max-width: 20vw;
`;

const Email = styled.a`
    font-size: 1.3rem;
    color: rgb(0, 0, 176);
    margin-left: 2rem;
    @media (max-width: 768px) {
      margin-left: .5rem;
    }
    text-decoration: none;
`;

export const SayHi = () => (
    <Container>
        <Video autoPlay={true} loop={true} muted={true} playsInline={true}>
            <source src={sayHi} type={'video/mp4'} />
        </Video>
        <Email href={'mailto://hello@annalaumer.com'}>hello@annalaumer.com</Email>
    </Container>
)