import { useEffect, useRef, useState } from 'react';
import React from 'react';
import styled from 'styled-components';

import logo from '../assets/annalaumi.gif';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 769px) {
        display: none;
    }
    z-index: 100;

`;

const LogoText = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -1rem;
  margin-left: -1.5rem;
  opacity: 1;
  transition: opacity .5s ease-in-out;
`;

const LogoTextRow = styled.span`
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(0, 0, 176);
  text-transform: lowercase;
  font-size: 1rem;
`;

const thresholds = {
    from: 50,
    to: 400
};

const scaleInterval = {
    from: 1,
    to: .5
};
export const MobileLogo = () => {
    const scrollPositionRef = useRef(typeof window !== 'undefined' ? window.pageYOffset : 0);
    const [widthMultiplier, setWidthMultiplier] = useState(scaleInterval.from);
    const [textVisible, setTextVisible] = useState(true);
    useEffect(
        () => {
            if (typeof window === 'undefined') {
                return;
            }

            const listener = () => {

                const prevPos = scrollPositionRef.current;
                const pos = window.pageYOffset;
                scrollPositionRef.current = pos;

                if (prevPos < thresholds.from && pos < thresholds.from) {
                    return;
                }
                if (pos > thresholds.to && pos > thresholds.to) {
                    return;
                }
                const rel = Math.min(1, (pos - thresholds.from) / (thresholds.to - thresholds.from));
                setWidthMultiplier(scaleInterval.from - rel * (scaleInterval.from - scaleInterval.to));


                const textVisibleAtScroll = pos < 50;
                const textVisibleBefore = prevPos < 50;
                if (textVisibleAtScroll !== textVisibleBefore) {
                    setTextVisible(textVisibleAtScroll);
                }
            }
            window.addEventListener('scroll', listener);
            window.addEventListener('touchmove', listener);

            return () => {
                window.removeEventListener('scroll', listener);
                window.removeEventListener('touchmove', listener);
            };
        },
        []
    );
    return (
        <Container>
            <img src={logo} style={{ width: `${widthMultiplier * 60}vw` }} alt={'Website logo'} />
            <LogoText style={{ opacity: textVisible ? 1 : 0 }}>
                <LogoTextRow>COMMUNICATION &</LogoTextRow>
                <LogoTextRow>MOTION DESIGN</LogoTextRow>
            </LogoText>
        </Container>
    )
}