import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import eyeImg from '../assets/augeweiss.gif';

const Overlay = styled.div`
  position: absolute;
  left: 0;
  width: 100%;

  @media (max-width: 768px) {
    bottom: 0;
    height: 50%;
    background-color: rgba(0, 0, 176, 0.7);
  }
  @media (min-width: 769px) {
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 176, 0);
    transition: background-color 0.25s ease-in-out;
    .overlay-items {
      opacity: 0;
      transition: opacity 0.75s ease-in-out;
    }
    &:hover {
      background-color: rgba(0, 0, 176, 0.7);

      .overlay-items {
        opacity: 1;
      }
    }
  }
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const OverlayItems = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Image = styled.img`
  max-height: 4rem;
  @media (max-width: 768px) {
    display: none;
  }
`

const Name = styled.div`
  margin-top: 0.5rem;
  color: #fff;
  font-size: 1.1rem;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
`

const Description = styled.div`
  margin-top: 0.3rem;
  color: #fff;
`

const ClickTarget = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
`

export const MasonryOverlay = ({ name, description, href }) => {
  return (
    <ClickTarget to={href}>
      <Overlay>
        <OverlayItems className={"overlay-items"}>
          <Image src={eyeImg} />
          <Name>{name}</Name>
          <Description>{description}</Description>
        </OverlayItems>
      </Overlay>
    </ClickTarget>
  )
}
