import React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    ${({ mobile }) =>
        mobile
            ? `
        @media (min-width: 769px) {
            display: none
        }
        `: `
        @media (max-width: 768px) {
            display: none
        }
        `
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const MasonryItem = styled.div`
    position: relative;
    width: 100%;

    @media (min-width: 769px) {
        ${({ itemOrder }) => !itemOrder.middleCol ? `` : 'img { height: unset !important;}'}
        ${({ itemOrder }) => itemOrder.isLeftCol ? `* { object-position: center left !important; };` : ''}
        ${({ itemOrder }) => itemOrder.isRightCol ? `* { object-position: center right !important; };` : ''}
    }
    @media (max-width: 768px) {
        width: 100%;
        order: ${({ itemOrder }) => itemOrder.mobile};
    }
    margin-bottom: 2rem;
    flex-shrink: 0;
    flex-grow: 0;
`;

const MasonryColumn = styled.div`
    width: 33.3333%;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    &:nth-child(1) {
        margin-top: 1rem;
    }
    &:nth-child(2) {
        margin-top: 12rem;
    }
    &:nth-child(3) {
        margin-top: 7rem;
    }
`;

export function getDesktopIndex(idx, columns, itemsPerColumn) {
    const itemCol = (idx % columns); // column of the item 0,1,2
    return itemCol * itemsPerColumn + (idx - itemCol) / (columns)
}

export const Masonry = ({ children, maxHeight }) => {
    const columns = 3;
    const items = React.Children.toArray(children);
    const itemsPerColumn = Math.ceil(items.length / columns);

    const itemsWithOrder = useMemo(
        () => {
            const res = items.map((item, idx) => {
                const desktopIndex = getDesktopIndex(idx, columns, itemsPerColumn);
                return {
                    key: idx,
                    item,
                    order: {
                        mobile: idx,
                        desktop: desktopIndex,
                        middleCol: (idx % columns !== 0) && (idx % columns !== columns - 1),
                        isLeftCol: idx % columns === 0,
                        isRightCol: idx % columns === columns - 1
                    }
                };
            });
            res.sort((a, b) => a.order.desktop - b.order.desktop);
            return res;
        },
        [items]
    );

    return (
        <>
            <Container itemsPerColumn={itemsPerColumn} mobile={false}>
                <MasonryColumn key={'column-1'}>
                    {itemsWithOrder.filter(i => i.order.isLeftCol)
                        .map(({ item, order, key }) => (
                            <MasonryItem itemOrder={order} key={key} maxHeight={maxHeight}>
                                {item}
                            </MasonryItem>
                        ))}
                </MasonryColumn>
                <MasonryColumn key={'column-2'}>
                    {itemsWithOrder.filter(i => i.order.middleCol)
                        .map(({ item, order, key }) => (
                            <MasonryItem itemOrder={order} key={key} maxHeight={maxHeight}>
                                {item}
                            </MasonryItem>
                        ))}
                </MasonryColumn>
                <MasonryColumn key={'column-3'}>
                    {itemsWithOrder.filter(i => i.order.isRightCol)
                        .map(({ item, order, key }) => (
                            <MasonryItem itemOrder={order} key={key} maxHeight={maxHeight}>
                                {item}
                            </MasonryItem>
                        ))}
                </MasonryColumn>
            </Container>
            <Container itemsPerColumn={itemsPerColumn} mobile={true}>
                {itemsWithOrder.map(({ item, order }) => (
                    <MasonryItem itemOrder={order} key={item.key} maxHeight={maxHeight}>
                        {item}
                    </MasonryItem>
                ))}
            </Container>
        </>
    )

}