import React from 'react';
import styled from 'styled-components';

const UntransformedImage = styled.img`
width: 100%;
height: 100%;
object-fit: contain;
` ;

export const MasonryImage = ({ src, alt, className, children }) => {
    return (

        <UntransformedImage src={src} alt={alt} />
    )
};